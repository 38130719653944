import { ChingariButton } from "chingari-components";
import PopupHeader from "../PopupHeader";
import { GenderData } from "./GenderData";
import styles from "./GenderSelection.module.scss";
import Image from "next/image";
import { LOGIN_FLOW_STRINGS } from "@/utils/strings";

interface GenderSelectionProps {
  value: string;
  onChange: (name: string, value: string) => void;
  onNext: () => void;
}

const GenderSelection = ({ value, onChange, onNext }: GenderSelectionProps) => {
  return (
    <div className={styles.genderWrapper}>
      <PopupHeader
        title={LOGIN_FLOW_STRINGS.GENDER_SELECTION_TITLE}
        description={LOGIN_FLOW_STRINGS.GENDER_SELECTION_DESC}
      />
      <div className={styles.genderList}>
        {GenderData.map(({ id, name, value: val, image }) => (
          <div
            key={id}
            className={`${styles.genderItem} ${
              value === val ? styles.selected : ""
            }`}
            onClick={() => onChange("gender", val)}
          >
            <p>{name}</p>
            <div className={styles.image}>
              <Image src={image} alt="gender-ic" width={110} height={115} />
            </div>
          </div>
        ))}
      </div>

      <ChingariButton
        title={LOGIN_FLOW_STRINGS.NEXT_BUTTON}
        className={styles.button}
        onClick={onNext}
        disabled={!value}
      />
    </div>
  );
};

export default GenderSelection;
