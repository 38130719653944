import { ChingariButton } from "chingari-components";
import PopupHeader from "../PopupHeader";
import { isAdult } from "../../../../utils/helper";
import styles from "./AgeSelection.module.scss";
import IosDatePicker from "@/common/components/IosDatePicker";
import { LOGIN_FLOW_STRINGS } from "@/utils/strings";

interface AgeSelectionProps {
  value: Date;
  onChange: (val: Date) => void;
  onNext: () => void;
}

const AgeSelection = ({ value, onChange, onNext }: AgeSelectionProps) => {
  const error = !isAdult(new Date(value));
  return (
    <div className={styles.ageSelectionWrapper}>
      <PopupHeader
        title={LOGIN_FLOW_STRINGS.AGE_POPUP_TITLE}
        description={LOGIN_FLOW_STRINGS.AGE_POPUP_DESC}
      />
      <IosDatePicker date={value} onChange={onChange} />
      {error && (
        <p className={styles.error}>{LOGIN_FLOW_STRINGS.AGE_POPUP_ERROR}</p>
      )}

      <ChingariButton
        title={LOGIN_FLOW_STRINGS.NEXT_BUTTON}
        className={styles.button}
        onClick={onNext}
        disabled={error}
      />
    </div>
  );
};

export default AgeSelection;
