"use client";
import { AppContext } from "@/context/AppContext";
import useSocket, { SocketOptionsType } from "@/customhooks/useSocket";
import { Config } from "@/utils/config";
import { useContext } from "react";
import {TrtcHandlerPropType} from "../TrtcHandler";
import { Socket } from "socket.io-client";
import dynamic from "next/dynamic";

const TrtcHandler = dynamic(() => import('../TrtcHandler'), {ssr: false}) as React.FC<TrtcHandlerPropType>;

const CustomInitializer = () => {
  const context = useContext(AppContext);
  const { token, userId } = context?.auth ?? {};
  const { setPrivateCallSocket } = context ?? {};
  const socketOptions: SocketOptionsType = {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 1000,
    reconnectionAttempts: 100,
    transports: ["websocket", "pooling"],
  };
  const handleSetPrivateCallSocket = (socket: Socket) => {
    setPrivateCallSocket && setPrivateCallSocket(socket);
  };
  const SOCKET_URL = Config.PRIVATE_CALL_URL;
  useSocket({
    SOCKET_URL,
    SOCKET_OPTIONS: socketOptions,
    TOKEN: token ?? "",
    SET_SOCKET_CONTEXT: handleSetPrivateCallSocket,
  });
  return (
    <>
      <TrtcHandler token={token} />
    </>
  );
};

export default CustomInitializer;
