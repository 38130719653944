"use client";

import { useContext, useEffect, useState } from "react";
import Image from "next/image";
import { CountryData } from "react-phone-input-2";
import Popup from "../../../common/components/Popup/Popup";
import LoginPopup from "./LoginPopup";
import UploadPic from "./UploadPic";
import LanguageSelection from "./LanguageSelection";
import MobileNumberPopup from "./MobileNumberPopup";
import OtpPopup from "./OtpPopup";
import NameInput from "./NameInput";
import { SignupData } from "./LoginFlow.type";
import GenderSelection from "./GenderSelection";
import AgeSelection from "./AgeSelection";
// import { IC_BACK, IC_CLOSE } from "../../utils/imageRelativePaths";
import {
  sendOtp,
  signup,
  updateProfilePic,
  verifyOtp,
} from "../../../services/auth.service";
import styles from "./LoginFlow.module.scss";
import WelcomePopup from "./WelcomePopup";
import { AppContext } from "@/context/AppContext";
import { PopupType } from "@/utils/common.types";
import { IC_BACK, IC_CLOSE } from "@/utils/imageRelativePaths";

const LoginFlow = ({ show, onClose }: PopupType) => {
  const [step, setStep] = useState(1);
  const [date, setDate] = useState(new Date());
  const [error, setError] = useState("");
  const [signupData, setSignupData] = useState<SignupData>();
  const { setLoader = () => null, setAuth } = useContext(AppContext) ?? {};

  const onFileChange = (file: File) => {
    setSignupData((prev) => ({ ...prev, profilePic: file }));
  };

  const onNumberChange = (val: string, data: CountryData) => {
    setSignupData((prev) => ({
      ...prev,
      countryCode: "+" + data?.dialCode,
      contact: val,
      country: data?.countryCode,
    }));
  };

  const onChange = (name: string, value: string) => {
    setSignupData((prev) => ({ ...prev, [name]: value }));
  };

  const onRequestOtp = async () => {
    if (signupData?.contact && signupData?.countryCode) {
      const contact = signupData?.contact?.slice(
        signupData?.countryCode?.length - 1
      );
      if (contact.length !== 10) return setError("Invalid number");
      setLoader(true);
      const response = await sendOtp(signupData?.countryCode, contact);
      setLoader(false);
      if (response?.status === 200) {
        setStep(3);
      }
    }
  };

  const onSignup = async () => {
    let contact = signupData?.contact?.slice(
      (signupData?.countryCode?.length ?? 1) - 1
    );
    let countryCode = signupData?.countryCode;
    if (!contact || !countryCode) {
      contact = "0";
      countryCode = "0";
    }
    setLoader(true);
    const response = await signup({
      ...signupData,
      contact,
      countryCode,
      country: signupData?.country?.toUpperCase(),
      language: signupData?.language?.toLowerCase(),
    });
    setLoader(false);

    if (response?.code === 200) {
      setAuth &&
        setAuth({
          token: response?.accessToken,
          userId: response?.userData?._id,
        });

      setTimeout(onNext, 1000);
    }
  };

  const onVerifyUser = async (otp: string) => {
    setSignupData((prev) => ({ ...prev, otp }));
    let contact = signupData?.contact?.slice(
      (signupData?.countryCode?.length ?? 1) - 1
    );
    let countryCode = signupData?.countryCode;
    if (!contact || !countryCode) {
      contact = "0";
      countryCode = "0";
    }
    setLoader(true);
    const response = await verifyOtp(
      countryCode,
      contact,
      otp,
      signupData?.firebaseId
    );
    setLoader(false);
    if (response?.code === 200) {
      setAuth &&
        setAuth({
          token: response?.accessToken,
          userId: response?.userData?._id,
        });

      handleClose();
    }
    if (response?.code === 400) {
      setError(response?.message);
    }
    if (response?.code === 404) {
      setStep(4);
    }
  };

  const onNext = () => {
    setStep((prev) => prev + 1);
  };

  const onPrev = () => {
    if (step === 1) return handleClose();
    setStep((prev) => prev - 1);
  };

  const handleClose = () => {
    setStep(1);
    setSignupData({});
    onClose();
  };

  useEffect(() => {
    setError("");
  }, [step]);

  useEffect(() => {
    if (signupData?.firebaseId) {
      void onVerifyUser("0");
    }
  }, [signupData?.firebaseId]);

  const renderStep = () => {
    switch (step) {
      case 1:
        return <LoginPopup onNext={onNext} onChange={onChange} />;
      case 2:
        return (
          <MobileNumberPopup
            error={error}
            number={signupData?.contact ?? ""}
            onNumberChange={onNumberChange}
            onSubmit={onRequestOtp}
          />
        );
      case 3:
        return (
          <OtpPopup
            error={error}
            onSubmit={onVerifyUser}
            onResend={() => void onRequestOtp()}
          />
        );
      case 4:
        return (
          <NameInput
            value={signupData?.name || ""}
            onChange={onChange}
            onNext={onNext}
          />
        );
      case 5:
        return (
          <GenderSelection
            value={signupData?.gender || ""}
            onChange={onChange}
            onNext={onNext}
          />
        );
      case 6:
        return (
          <AgeSelection
            value={date}
            onChange={(date: Date) => {
              setDate(date);
              onChange(
                "birthDate",
                `${
                  date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
                }/${
                  date.getMonth() + 1 < 10
                    ? `0${date.getMonth() + 1}`
                    : date.getMonth() + 1
                }/${date.getFullYear()}`
              );
            }}
            onNext={onNext}
          />
        );
      case 7:
        return (
          <UploadPic
            file={signupData?.profilePic as File}
            onFileChange={onFileChange}
            onNext={onNext}
          />
        );
      case 8:
        return (
          <LanguageSelection
            value={signupData?.language || ""}
            onChange={onChange}
            onNext={() => void onSignup()}
          />
        );
      case 9:
        return <WelcomePopup />;
      default:
        return null;
    }
  };

  return (
    <Popup show={show} onClose={handleClose} className={styles.popupWrapper}>
      <div className={styles.loginFlowWrapper}>
        <div className={styles.topIcons}>
          <div className={styles.icon} onClick={onPrev}>
            <Image src={IC_BACK} alt="back" width={16} height={12} />
          </div>
          <div className={styles.icon} onClick={handleClose}>
            <Image src={IC_CLOSE} alt="back" width={12} height={12} />
          </div>
        </div>
        {renderStep()}
      </div>
    </Popup>
  );
};

export default LoginFlow;
