import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

interface firebaseConfigType {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
  databaseURL: string;
}

// Your web app's Firebase configuration
const firebaseConfig: firebaseConfigType = {
  apiKey: "AIzaSyDaGnZKnsdQJRqvM5jrjHTA3s1XoVJ26-8",
  authDomain: "chingari-5f62f.firebaseapp.com",
  databaseURL: "https://chingari-5f62f.firebaseio.com",
  projectId: "chingari-5f62f",
  storageBucket: "chingari-5f62f.appspot.com",
  messagingSenderId: "1082487018579",
  appId: "1:1082487018579:web:2a1f322051ab5cae96a2a6",
  measurementId: "G-80S60TTHDH",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export default app;
