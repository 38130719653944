/* eslint-disable import/no-unresolved */
import React, {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  cloneElement,
  useState,
  useEffect,
  useContext,
} from "react";
import Lottie from "react-lottie";
import { ChingariButton, Loader } from "chingari-components";
import Popup from "../Popup/Popup";
import styles from "./ReviewPopup.module.scss";
import Image from "next/image";
import {
  DEFAULT_USER_AVATAR,
  REVIEW_POPUP_ICONS,
  RIGHT_MARK_ICON,
} from "@/utils/imageRelativePaths";
import ImageWithFallback from "../ImageWithFallback";
import { AppContext } from "@/context/AppContext";
import { addReviewFeedback, fetchReviewItems } from "@/services/rooms.service";
import { AddReviewReq, ReviewItem } from "./ReviewPopup.types";
import { convertSecondsToTime } from "@/utils/helper";
import * as THANKS_LOTTIE from "../../../public/assets/lotties/thanks.json";
import Error from "next/error";

interface reviewDataTypes {
  rating: number;
  reviewOption: string[];
  message: string;
}

interface reviewPropsType {
  show: boolean;
  onClose: () => void;
}
const ReviewPopup = ({ show, onClose }: reviewPropsType) => {
  const context = useContext(AppContext);
  const { token = "" } = context?.auth ?? {};
  const { privateCallData, setToastData, loader, setLoader } = context ?? {};
  const { contentOwner } = privateCallData ?? {};
  const [reviewData, setReviewData] = useState<AddReviewReq>({
    profileId: "",
    rating: 0,
    reviewItems: [],
    comment: "",
    roomType: "pc",
    sessionId: "",
  });
  const [reviewItemList, setReviewItemList] = useState<ReviewItem[]>([]);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  interface customErrorType extends Error {
    message: string;
  }

  const submitHandler = async () => {
    try {
      setIsLoading(true);
      const response = await addReviewFeedback(token, reviewData);
      if (response?.statusCode === 201) {
        setSuccess(true);
      } else {
        setToastData &&
          setToastData({
            message: response?.message ?? "",
            type: "error",
            visible: true,
          });
      }
    } catch (error) {
      console.log(error);
      const customError: { message: string } = error as customErrorType;
      setToastData &&
        setToastData({
          message: customError?.message,
          type: "error",
          visible: true,
        });
    } finally {
      setIsLoading(false);
    }
  };

  const inputHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setReviewData((pre) => {
      return {
        ...pre,
        [name]: value,
      };
    });
  };

  const getReviewItemList = async () => {
    // const response = await fetchReviewItems(token);
    // setReviewItemList(response?.data ?? []);
  };

  useEffect(() => {
    if (privateCallData?.channelId) {
      setReviewData((prev) => ({
        ...prev,
        sessionId: privateCallData?.channelId ?? "",
        profileId: privateCallData?.contentOwner?.userId ?? "",
      }));
    }
  }, [privateCallData]);

  useEffect(() => {
    show && void getReviewItemList();
  }, [show]);

  useEffect(() => {
    if (loader) {
      setLoader && setLoader(false);
    }
  }, [loader]);

  const renderRatingStars = () => {
    const starCount: number[] = [1, 2, 3, 4, 5];
    return (
      <div className={styles.starsContainer}>
        {starCount.map((cElement, index) => {
          return (
            <Image
              key={index}
              src={
                cElement <= reviewData.rating
                  ? REVIEW_POPUP_ICONS.REVIEW_IC_STAR_golden
                  : REVIEW_POPUP_ICONS.REVIEW_IC_STAR
              }
              alt="star"
              width={39.8}
              height={37.9}
              className={styles.starIcon}
              onClick={() => {
                setReviewData((pre) => ({
                  ...pre,
                  rating: cElement,
                  reviewItems: pre.rating === cElement ? pre.reviewItems : [],
                }));
              }}
            />
          );
        })}
      </div>
    );
  };

  const renderReviewOptions = () => {
    const renderReviewOptionData: ReviewItem[] = reviewItemList?.filter(
      ({ ratingValue }) => ratingValue === reviewData.rating
    );

    return (
      <div className={styles.ReviewOptionContainer}>
        {renderReviewOptionData.map((cElement, index) => (
          <button
            key={index}
            className={`${styles.optionButton} ${
              reviewData.reviewItems.includes(cElement.id)
                ? styles.selectedButton
                : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              setReviewData((pre) => {
                if (pre.reviewItems.includes(cElement.id)) {
                  return {
                    ...pre,
                    reviewItems: pre.reviewItems.filter(
                      (c) => c != cElement.id
                    ),
                  };
                }

                return {
                  ...pre,
                  reviewItems: [...pre.reviewItems, cElement.id],
                };
              });
            }}
          >
            {cElement.item.name}
          </button>
        ))}
      </div>
    );
  };

  const renderPopupContent = () => {
    let content = (
      <>
        <div className={styles.popupContentWrapper}>
          {success ? (
            <div className={styles.successWrapper}>
              {/* @ts-expect-error Server Component */}
              <Lottie
                width={240}
                height={260}
                options={{ animationData: THANKS_LOTTIE }}
                isClickToPauseDisabled={true}
              />
              <div className={styles.right_image_wraper}>
                <Image
                  src={RIGHT_MARK_ICON}
                  alt="right icon"
                  width={34.83}
                  height={24.88}
                />
              </div>
              <p className={styles.text}>Thanks for sharing your review</p>
              <ChingariButton
                title="Go To Home"
                className={styles.button}
                onClick={onClose}
              />
            </div>
          ) : (
            <div>
              <div className={styles.headerContainer}>
                <ImageWithFallback
                  src={contentOwner?.photo ?? ""}
                  alt="avater"
                  height={88.57}
                  width={88.57}
                  name={contentOwner?.name}
                  className={styles.userImg}
                />
                <div className={styles.headerText}>
                  <p>{contentOwner?.name}</p>
                  <span>
                    Call duration:{" "}
                    {convertSecondsToTime(privateCallData?.duration ?? 0)}
                  </span>
                </div>
              </div>

              <div className={styles.bodyContainer}>
                <form>
                  <div className={styles.bodyContentWrapper}>
                    <span>Rate your call quality and experience</span>
                    {renderRatingStars()}
                    {/* {renderReviewOptions()} */}
                    <textarea
                      className={styles.messageInput}
                      placeholder="Add your comments..."
                      onChange={inputHandler}
                      name="comment"
                      value={reviewData.comment}
                    />
                  </div>
                </form>
              </div>
              <button
                className={`${styles.submitButton} ${
                  reviewData.rating < 1 ? styles.isDisabled : ""
                }`}
                onClick={() => void submitHandler()}
                disabled={reviewData.rating > 0 ? false : true}
              >
                Submit
              </button>
            </div>
          )}
        </div>
        <Image
          src={REVIEW_POPUP_ICONS.REVIEW_IC_BACK_BLACK}
          alt="back Icon"
          height={32.66}
          width={32}
          className={styles.backIcon}
          onClick={onClose}
        />
      </>
    );

    if (isLoading) {
      content = (
        <Loader
          loaderContainerStyles={{
            top: 0,

            left: 0,
            background: "rgba(0, 0, 0, 0.7)",
          }}
        />
      );
    }

    return content;
  };

  return (
    <div className={styles.popupWrapper}>
      <Popup show={show} onClose={onClose} onClickOutside={false}>
        {renderPopupContent()}
      </Popup>
    </div>
  );
};

export default ReviewPopup;
