"use client";
import Popup from "../Popup/Popup";
import styles from "./LogoutPopup.module.scss";
import { useContext } from "react";
import { useRouter } from "next/navigation";
import { AppContext } from "@/context/AppContext";
import { LOGOUT_POPUP_STRINGS } from "@/utils/strings";

interface LogoutPopupProps {
  show: boolean;
  onClose: () => void;
}

const LogoutPopup = ({ show, onClose }: LogoutPopupProps) => {
  const router = useRouter();
  const context = useContext(AppContext);
  const { setUserData, setAuth } = context ?? {};

  const onLogOut = () => {
    setUserData && setUserData(null);
    setAuth && setAuth({});
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    onClose();
    void router.replace("/");
  };

  return (
    <Popup show={show} onClose={onClose}>
      <div className={styles.logoutPopupWrapper}>
        <h3 className={styles.title}>{LOGOUT_POPUP_STRINGS.TITLE}</h3>
        <p className={styles.desc}>{LOGOUT_POPUP_STRINGS.DESC}</p>
        <div className={styles.buttons}>
          <div onClick={onClose} className={styles.button}>
            {LOGOUT_POPUP_STRINGS.NO_BTN}
          </div>
          <div onClick={onLogOut} className={styles.button}>
            {LOGOUT_POPUP_STRINGS.YES_BTN}
          </div>
        </div>
      </div>
    </Popup>
  );
};

export default LogoutPopup;
