/* eslint-disable import/no-unresolved */
import React, { useContext } from "react";
import styles from "./CallingPopup.module.scss";
import Image from "next/image";
import Lottie from "react-lottie";
import { CALLING_POPUP_ICONS } from "@/utils/imageRelativePaths";
import * as animationData from "../../../public/assets/lotties/CallingLottie.json";
import { AppContext } from "@/context/AppContext";
import { endCall } from "@/services/call.service";

const CallingPopup = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const { privateCallData, trtcClient, privateCallSocket } =
    useContext(AppContext) ?? {};
  return (
    <div className={styles.CallingPopupWraper}>
      <Image
        src={privateCallData?.caller?.photo ?? ""}
        alt="user"
        height={46}
        width={46}
      />
      <div className={styles.lottie}>
        {/* @ts-expect-error Server Component */}
        <Lottie options={defaultOptions} height={33} width={71} />
      </div>
      <Image
        src={privateCallData?.contentOwner?.photo ?? ""}
        alt="user"
        height={46}
        width={46}
      />
      <button
        onClick={() =>
          privateCallSocket &&
          trtcClient &&
          void endCall(trtcClient, privateCallSocket)
        }
      >
        <Image
          src={CALLING_POPUP_ICONS.CALLING_ICON}
          alt="user"
          height={25}
          width={25}
        />
      </button>
    </div>
  );
};

export default CallingPopup;
