const LOGIN_FLOW_STRINGS = {
  LOGIN_TITLE: "First, you need to login",
  LOGIN_DESC: "Connect with experienced astrologers instantly on AstroLive!",
  PHONE_LOGIN: "Phone Number",
  GOOGLE_LOGIN: "Google",
  MOBILE_POPUP_TITLE: "Enter mobile number",
  MOBILE_POPUP_DESC: "We will send an OTP to this mobile number to verify.",
  MOBILE_POPUP_INPUT_LABEL: "Your Mobile number",
  MOBILE_POPUP_SUBMIT_BTN: "Send OTP",
  OTP_POPUP_TITLE: "Enter OTP",
  OTP_POPUP_CODE_EXPIRE: "Your code will expire in",
  OTP_POPUP_NOT_RECEIVED: "Didn’t receive the OTP?",
  OTP_POPUP_RESEND: "Resend OTP",
  OTP_SUBMIT_BTN: "Submit & Verify",
  NAME_INPUT_TITLE: "👋 Hello!",
  NAME_INPUT_PLACEHOLDER: "Enter your nickname",
  GENDER_SELECTION_TITLE: "What’s your gender?",
  GENDER_SELECTION_DESC: "Please let us know which gender best describe you",
  AGE_POPUP_TITLE: "How old are you?",
  AGE_POPUP_DESC: "We will make sure you get better and personalised results",
  AGE_POPUP_ERROR: "Not allowed to use under 18",
  UPLOAD_PIC_TITLE: "Upload your profile",
  UPLOAD_PIC_DESC:
    "A profile picture add personal touch to your profile that help you attract more people.",
  LANG_SELECT_TITLE: "Choose language",
  LANG_SELECT_DESC: "Select languages you would prefer to speak in",
  WELCOME_DESC: "your profile has been Successfully created",
  WELCOME_TEXT: "You can update your details in settings at any time",
  SKIP_BUTTON: "Skip",
  NEXT_BUTTON: "Next",
};

const LOGOUT_POPUP_STRINGS = {
  TITLE: "Log out",
  DESC: "Are you sure you want to log out?",
  NO_BTN: "No",
  YES_BTN: "Yes",
};

const ERROR = {
  heading: "OOPS !",
  decription: "Please try again after sometime.",
};

const NETWORK_ERROR_MESSAGE =
  "Oops! Couldn’t start the call because of a network error.";

const RETRY_BUTTON_TITLE = "Try again";

const EMPTY_DATA = "No Data Found";

const DOWNLOAD_APP_TEXT = "Download the app",
  SUGGESTION_TEXT = "Suggestions",
  FOLLOW_BTN_TEXT = "Follow",
  GIFT_TITLE = "Gift",
  GIFT_TEXT = "Send gift",
  CHAT_TITLE = "Chat",
  CHAT_TEXT = "Click to chat",
  JOIN_TEXT = "Join Live",
  DOWNLOAD_TEXT = "DOWNLOAD",
  CHINGARI_APP = "ASTROLIVE APP",
  OPEN_APP_CTA = "Open the Astrolive App",
  SOMETHING_WRONG = "Something went wrong",
  ROOM_ERROR = "This room is currently not live";

const ROOM_CONTAINER_STRINGS = {
  TITLE: "Astrolive Creator",
  ACTION_BUTTON_TITLE: "Join Now",
  REDIRECTION_TITLE:
    "Don't have a Astrolive account? <br /> Get the app to join the conversation!",
};

// const ToastTypes: ToastType = {
//   SUCCESS: "success",
//   ERROR: "error",
//   WARNING: "Warning",
// };
export const FOLLOWING_BTN_TEXT = "Following";

export {
  ERROR,
  EMPTY_DATA,
  DOWNLOAD_APP_TEXT,
  SUGGESTION_TEXT,
  FOLLOW_BTN_TEXT,
  GIFT_TITLE,
  GIFT_TEXT,
  CHAT_TITLE,
  CHAT_TEXT,
  JOIN_TEXT,
  DOWNLOAD_TEXT,
  CHINGARI_APP,
  OPEN_APP_CTA,
  SOMETHING_WRONG,
  ROOM_ERROR,
  RETRY_BUTTON_TITLE,
  NETWORK_ERROR_MESSAGE,
  ROOM_CONTAINER_STRINGS,
  LOGIN_FLOW_STRINGS,
  LOGOUT_POPUP_STRINGS,
};
