import React, { useEffect, useState } from "react";
import Image, { ImageProps } from "next/image";
import styles from "./ImageFallback.module.scss";
import { getUsernameColor } from "@/utils/helper";
import { Images } from "@/utils/imageRelativePaths";

interface ImageWithFallbackProps extends ImageProps {
  fallbackSrc?: string;
  name?: string;
}

const ImageWithFallback = ({
  src,
  fallbackSrc,
  name,
  ...rest
}: ImageWithFallbackProps) => {
  const [imgSrc, setImgSrc] = useState(src);
  const { width, height = 0, className } = rest;

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  return !imgSrc && name ? (
    <p
      className={`${styles.nameChar} ${className}`}
      style={{
        width,
        height,
        backgroundColor: getUsernameColor(name),
        fontSize: Number(height) / 2,
      }}
    >
      {name?.[0]}
    </p>
  ) : (
    <Image
      {...rest}
      src={imgSrc}
      onError={() => {
        if (name) setImgSrc("");
        else setImgSrc(fallbackSrc ?? Images.iconFireOrange);
      }}
    />
  );
};

export default ImageWithFallback;
