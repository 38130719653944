"use client";
import { useEffect, useState } from "react";
import { io, Socket } from "socket.io-client";

type UseSocket = {
  socket: Socket | null;
};

export interface SocketOptionsType {
  reconnection: boolean;
  reconnectionDelay: number;
  reconnectionDelayMax: number;
  reconnectionAttempts: number;
  transports: string[];
  auth?: {
    token: string;
  };
}

interface useSocketParameterType {
  SOCKET_URL: string;
  SOCKET_OPTIONS: SocketOptionsType;
  TOKEN: string;
  SET_SOCKET_CONTEXT: (socket: Socket) => void;
}

const useSocket = ({
  SOCKET_URL,
  SOCKET_OPTIONS,
  TOKEN,
  SET_SOCKET_CONTEXT,
}: useSocketParameterType): UseSocket => {
  const [socket, setSocket] = useState<Socket | null>(null);

  const Initializer = () => {
    try {
      SOCKET_OPTIONS.auth = {
        token: TOKEN,
      };
      const instance = io(SOCKET_URL, SOCKET_OPTIONS);
      return instance;
    } catch (error) {
      console.log(error);
      if (error) throw error;
    }
  };

  useEffect(() => {
    if (TOKEN && !socket) {
      const socketInstance = Initializer();
      socketInstance && SET_SOCKET_CONTEXT(socketInstance);
      socketInstance && setSocket(socketInstance);

      return () => {
        socketInstance?.disconnect();
      };
    }
  }, [TOKEN]);

  return {
    socket,
  };
};

export default useSocket;
