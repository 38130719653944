import { useRef } from "react";
import styles from "./popup.module.scss";
import useOnClickOutside from "@/customhooks/useOnClickOutside";

interface PopupProps {
  show?: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  onClickOutside?: boolean;
}

const Popup = ({
  show = true,
  onClose,
  children,
  className = "",
  onClickOutside = true,
}: PopupProps) => {
  const containerRef: React.RefObject<HTMLDivElement> = useRef(null);

  const handleOutsideClick = () => {
    onClose();
  };

  onClickOutside && useOnClickOutside(containerRef, handleOutsideClick);

  if (!show) return null;

  return (
    <div
      className={`${styles.popupContainer} ${
        show ? styles.show : ""
      } ${className} ${!onClickOutside ? styles.reviewPopup : ""}`}
    >
      <div className={styles.popupInner} ref={containerRef}>
        {children}
      </div>
    </div>
  );
};

export default Popup;
