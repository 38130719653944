import Image from "next/image";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import PopupHeader from "../PopupHeader/index";
import styles from "./LoginPopup.module.scss";
import { auth } from "../firebase/firebase.config";
import { LOGIN_FLOW_STRINGS } from "@/utils/strings";
import { ASTRO_LOGO_NAME, LOGIN_FLOW_ICONS } from "@/utils/imageRelativePaths";

const LoginPopup = ({
  onNext,
  onChange,
}: {
  onNext: () => void;
  onChange: (name: string, value: string) => void;
}) => {
  const login = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const data = await signInWithPopup(auth, provider);
      const idToken = await data.user.getIdToken();
      onChange("firebaseId", idToken);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.loginWrapper}>
      <Image src={ASTRO_LOGO_NAME} alt="logo" width={160} height={50} />
      <PopupHeader
        title={LOGIN_FLOW_STRINGS.LOGIN_TITLE}
        description={LOGIN_FLOW_STRINGS.LOGIN_DESC}
      />
      <div className={styles.socialBtns}>
        <div className={styles.socialBtn} onClick={onNext}>
          <Image
            src={LOGIN_FLOW_ICONS.IC_PHONE}
            alt="social-icon"
            width={34}
            height={34}
          />
          {LOGIN_FLOW_STRINGS.PHONE_LOGIN}
        </div>
        <div className={styles.socialBtn} onClick={() => void login()}>
          <Image
            src={LOGIN_FLOW_ICONS.IC_GOOGLE}
            alt="social-icon"
            width={34}
            height={34}
          />
          {LOGIN_FLOW_STRINGS.GOOGLE_LOGIN}
        </div>
      </div>
    </div>
  );
};

export default LoginPopup;
