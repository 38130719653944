import PhoneInput, { CountryData } from "react-phone-input-2";
import { ChingariButton } from "chingari-components";
import PopupHeader from "../PopupHeader";
import styles from "./MobileNumberPopup.module.scss";
import "react-phone-input-2/lib/style.css";
import { LOGIN_FLOW_STRINGS } from "@/utils/strings";

interface MobilePopupInterface {
  error?: string;
  number: string;
  onNumberChange: (val: string, data: CountryData) => void;
  onSubmit: () => Promise<void>;
}

const MobileNumberPopup = ({
  error,
  number,
  onNumberChange,
  onSubmit,
}: MobilePopupInterface) => {
  return (
    <div className={styles.mobileWrapper}>
      <PopupHeader
        title={LOGIN_FLOW_STRINGS.MOBILE_POPUP_TITLE}
        description={LOGIN_FLOW_STRINGS.MOBILE_POPUP_DESC}
      />
      <div className={styles.phoneInputWrapper}>
        <label>{LOGIN_FLOW_STRINGS.MOBILE_POPUP_INPUT_LABEL}</label>
        <PhoneInput
          inputProps={{ autoFocus: true }}
          country={"in"}
          value={number}
          onChange={onNumberChange}
          containerClass={styles.phoneContainer}
          inputClass={styles.phoneInput}
          buttonClass={styles.dropdown}
        />
        {error && <p className={styles.error}>{error}</p>}
      </div>
      <ChingariButton
        title={LOGIN_FLOW_STRINGS.MOBILE_POPUP_SUBMIT_BTN}
        className={styles.btn}
        onClick={() => void onSubmit()}
      />
    </div>
  );
};

export default MobileNumberPopup;
