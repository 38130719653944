import { useContext } from "react";
import Image from "next/image";
import Lottie from "react-lottie";
import * as WELCOME_LOTTIE from "../../../../public/assets/lotties/welcome.json";
// import { LOGIN_FLOW_STRINGS } from "../../../utils/strings";
// import { AppContext } from "../../../context/AppContext";
import styles from "./WelcomePopup.module.scss";
import { AppContext } from "@/context/AppContext";
import { LOGIN_FLOW_STRINGS } from "@/utils/strings";

const WelcomePopup = () => {
  const context = useContext(AppContext);
  const { userData } = context ?? {};
  return (
    <div className={styles.welcomeWrapper}>
      <div className={styles.lottieWrapper}>
        {/* @ts-expect-error Server Component */}
        <Lottie
          options={{ animationData: WELCOME_LOTTIE }}
          isClickToPauseDisabled={true}
        />
      </div>
      <div className={styles.innerWrapper}>
        <Image
          src={userData?.profilePic ?? ""}
          alt="profile"
          width={142}
          height={142}
        />
        <h3 className={styles.title}>{userData?.name}</h3>
        <p className={styles.desc}>{LOGIN_FLOW_STRINGS.WELCOME_DESC}</p>
        <p className={styles.text}>{LOGIN_FLOW_STRINGS.WELCOME_TEXT}</p>
      </div>
    </div>
  );
};

export default WelcomePopup;
