"use client";
import Popup from "@/common/components/Popup/Popup";
import styles from "./styles.module.scss";
import Script from "next/script";
import axios from "axios";
import useAppContext from "@/customhooks/useAppContext";
import Image from "next/image";
import { CLOSE_ICON_GRAY, DIAMOND_ICON_BLUE } from "@/utils/imageRelativePaths";
import { useContext, useEffect, useState } from "react";
import { getDiamondPlans } from "@/services/common.service";
import { getDiamondPlansResponse } from "@/utils/common.types";
import { Loader } from "chingari-components";

interface BuyPopUpppropType {
  show: boolean;
  onClose: () => void;
}

const BuyPopUp = ({ show, onClose }: BuyPopUpppropType) => {
  const context = useAppContext();
  const { setPrivateCallData } = context;
  const token = context.auth.token ?? "";
  const { setLoader } = context ?? "";
  const [PurchesPlans, setPurchesPlans] = useState<getDiamondPlansResponse[]>(
    []
  );
  const checkoutHandler = async (planId: string) => {
    setLoader(true);
    setPrivateCallData({ purchasePlan: planId });
  };

  useEffect(() => {
    return () => {
      setLoader(false);
    };
  }, []);

  const fetchDiamondPlans = async () => {
    try {
      const plans = await getDiamondPlans(token);
      if (plans) {
        setPurchesPlans(plans);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      void fetchDiamondPlans();
    }
  }, []);
  if (PurchesPlans.length <= 0) {
    return (
      <Loader
        loaderContainerStyles={{
          top: 0,
          background: "rgba(0, 0, 0, 0.5)",
        }}
      />
    );
  }
  return (
    <>
      <Script src="https://checkout.razorpay.com/v1/checkout.js" />
      <Popup
        show={show}
        // onClickOutside={true}
        className={styles.popupCont}
        onClose={onClose}
      >
        {/*<div className={styles.popupContentCont}>*/}
        {/* <h2>Low Diamonds Balance</h2>
        <h1>Purchase the diamond to make your call</h1>
        <button onClick={() => checkoutHandler()}>Buy Now-20000</button>
        <button onClick={() => checkoutHandler("661d211c21c6c785b427c9bc")}>
          Buy Now -
        </button> */}
        {/*</div>*/}

        <div className={styles.buy_pupup_container}>
          <div className={styles.header}>Low Diamonds Balance</div>
          <div className={styles.description}>
            Purchase the diamond to make your call
          </div>
          {/* <div className={styles.wallet_diamonds_count}>
            <Image
              src={DIAMOND_ICON_PINK}
              alt="dimond-ic"
              height={29}
              width={31}
            />
            <span className={styles.count}>200</span>
          </div> */}
          <div className={styles.diamond_plans_section}>
            {PurchesPlans.map((cElement) => {
              if (cElement.diamonds <= 0) {
                return null;
              }
              return (
                <div
                  className={styles.plan_card}
                  onClick={() => void checkoutHandler(cElement._id)}
                >
                  <Image
                    src={DIAMOND_ICON_BLUE}
                    alt="diamond"
                    height={40}
                    width={44}
                    className={styles.diamong_icon}
                  />
                  <div className={styles.price_section}>
                    <div className={styles.diamond_count}>
                      {cElement.diamonds}
                    </div>
                    <div className={styles.price_container}>
                      <div className={styles.cost_price}>
                        ₹<s>{cElement.costPrice}</s>
                      </div>
                      <div className={styles.sale_price}>
                        ₹{cElement.salePrice}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <Image
            src={CLOSE_ICON_GRAY}
            alt="close"
            height={15}
            width={15}
            className={styles.close_buy_popup}
            onClick={onClose}
          />
        </div>
      </Popup>
    </>
  );
};
export default BuyPopUp;
