import { useState } from "react";
import { ChingariButton } from "chingari-components";
import styles from "./OtpPopup.module.scss";
import useTimer from "@/customhooks/usetimer";
import { LOGIN_FLOW_STRINGS } from "@/utils/strings";
import OtpInput from "@/common/components/OtpInput";

const OtpPopup = ({
  error,
  onResend,
  onSubmit,
}: {
  error?: string;
  onResend: () => void;
  onSubmit: (val: string) => Promise<void>;
}) => {
  const [otp, setOtp] = useState("");
  const { minutes, seconds, handleRestart } = useTimer(0, 20);

  return (
    <div className={styles.otpWrapper}>
      <p className={styles.title}>{LOGIN_FLOW_STRINGS.OTP_POPUP_TITLE}</p>
      <OtpInput valueLength={6} value={otp} onChange={setOtp} />
      <div className={styles.text}>
        <p className={styles.expire}>
          {LOGIN_FLOW_STRINGS.OTP_POPUP_CODE_EXPIRE} {minutes}:
          {seconds > 9 ? seconds : `0${seconds}`}
        </p>
        <p className={styles.notReceived}>
          {LOGIN_FLOW_STRINGS.OTP_POPUP_NOT_RECEIVED}
          <span
            onClick={() => {
              minutes + seconds === 0 && onResend();
              minutes + seconds === 0 && handleRestart(0, 20);
            }}
            className={`${
              minutes + seconds > 0 ? styles.resend_otp_disabled : ""
            }`}
          >
            {LOGIN_FLOW_STRINGS.OTP_POPUP_RESEND}
          </span>
        </p>
        {error && <p className={styles.error}>{error}</p>}
      </div>

      <ChingariButton
        title={LOGIN_FLOW_STRINGS.OTP_SUBMIT_BTN}
        className={styles.button}
        onClick={() => void onSubmit(otp)}
      />
    </div>
  );
};

export default OtpPopup;
