import React, { useContext, useState } from "react";
import Image from "next/image";
import styles from "./CallEndAndMuteButton.module.scss";
import { AppContext } from "@/context/AppContext";
import { endCall, muteRemoteAudio } from "@/services/call.service";
import { Feed_CALL_BUTTON_ICONS } from "@/utils/imageRelativePaths";

const CallEndAndMuteButton = () => {
  const context = useContext(AppContext);
  const { trtcClient, privateCallSocket, setLoader } = context ?? {};
  const [isMuted, setIsMuted] = useState<boolean>(false);

  const endCallHandler = async () => {
    try {
      setLoader && setLoader(true);
      if (trtcClient && privateCallSocket)
        await endCall(trtcClient, privateCallSocket);
    } catch (error) {
      console.log(error);
    }
  };

  const muteButtenHandler = async () => {
    try {
      setIsMuted(!isMuted);
      if (trtcClient && privateCallSocket)
        await muteRemoteAudio(trtcClient, !isMuted, privateCallSocket);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={styles.CallEndAndMuteButtonWraper}>
      <button
        className={styles.closeButton}
        onClick={() => void endCallHandler()}
      >
        <Image
          src={Feed_CALL_BUTTON_ICONS.CLOSE_ICON}
          alt="Close Icon"
          height={15}
          width={15}
        />
      </button>

      <button
        className={`${styles.muteButton} ${isMuted ? styles.mutedButton : ""}`}
        onClick={() => void muteButtenHandler()}
      >
        <Image
          src={
            isMuted
              ? Feed_CALL_BUTTON_ICONS.MUTE_ICON_WHITE
              : Feed_CALL_BUTTON_ICONS.MUTE_ICON
          }
          alt="Mute Icon"
          height={18.61}
          width={18.61}
        />
      </button>
    </div>
  );
};

export default CallEndAndMuteButton;
