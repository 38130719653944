export const Astro_Strings = {
  DAILY_HOROSCOPE: "Daily Horoscope",
  YESTERDAY: "Yesterday",
  TODAY: "Today",
  TOMORROW: "Tomorrow",
  MONTHLY_HOROSCOPE: "Monthly Horoscope",
  YEARLY_HOROSCOPE: "Yearly Horoscope",
  ALSO_CHECK: "Also Check",
  TODAYS_PANCHANG: "Today’s Panchang",
  DATE_STRING: "Tue, 28 November 2023",
  AUSPICIOUS_TIMING: "Auspicious Timings",
  INAUSPICIOUS_TIMING: "Inauspicious Timings",
  SHUBHA_MUHURAT: "(Shubha Muhurat)",
  ASHUBHA_MUHURAT: "(Ashubha Muhurat)",
  ADDITIONAL_DETAILS: "Additional Details",
  NEED_MORE_GUIDANCE: "Need More Guidance",
  CONNECT_WITH_ASTROLOGER: "Connect with an astrologer",
  LIVE_ASTROLOGERS: "Live Astrologers",
  KUNDLI_CENTER: "Astro Hub",
  KUNDLIS_MATCH: "Kundli’s Match",
  FREE_KUNDLI: "Free Kundli",
  COMPARIBILITY_SCORE: "Compatibility Score",
  MANGLIK_REPORT: "Manglik Matching Report",
  MANGLIK: "Manglik",
  NON_MANGLIK: "Non-Manglik",
  DELETE_POPUP_TITLE: "Delete Profile",
  DELETE_POPUP_DESC: "Are you sure want to delete this profile?",
  ASTROLOGERS: "Astrologers",
  KUNDLI_MATCH_DESCRIPTION:
    "Find your perfect match with our Kundli Match feature! Using detailed astrological charts, this tool assesses compatibility and provides insights into the strengths and challenges of your relationship. Discover the harmony between you and your partner for a happier, more fulfilling connection.",
  PAYMENT_SUCCESS: "Payment Success",
  PAYMENT_FAILED: "Payment Failed",
  PAYMENT_MESSAGE: "Diamonds are successfully added to your wallet",
  AVAILABLE_BALANCE: "Available Balance",
};

export const EventNames = {
  VideoFeedClicked: "Video Feed Clicked",
  RoomIconClicked: "Room Icon Clicked",
  RoomHomepageOpened: "Rooms Homepage Opened",
  RoomOpened: "Room Opened",
  JoinLiveClicked: "Join Live Clicked",
  SendGiftsClicked: "Send Gifts Clicked",
  FollowClicked: "Follow Clicked",
  ChatClicked: "Chat Clicked",
  ProfileIconClicked: "Profile Icon Clicked",
  DownloadApp: "Download App",
  DownloadAppPopupDisplayed: "Download App Popup Displayed",
};

export const SOCKET_ERROR_TYPES = {
  INSUFFICIENT_BALANCE: "INSUFFICIENT_BALANCE",
};
