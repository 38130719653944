const LangData = [
    {
        id:1,
        name:"Kannada",
        langName:"ಕನ್ನಡ",
        value:"Kannada"
    },
    {
        id:2,
        name:"Marathi",
        langName:"मराठी",
        value:"Marathi"
    },
    {
        id:3,
        name:"English",
        langName:"English",
        value:"English"
    },
    {
        id:4,
        name:"Telegu",
        langName:"తెలుగు",
        value:"Telegu"
    }
    ,
    {
        id:5,
        name:"Odia",
        langName:"ଘୃଣା",
        value:"Odia"
    }
    ,
    {
        id:6,
        name:"Tamil",
        langName:"தமிழ்",
        value:"Tamil"
    }
    ,
    {
        id:7,
        name:"Malayalam",
        langName:"മലയാളം",
        value:"Malayalam"
    },
    {
        id:8,
        name:"Punjabi",
        langName:"ਪੰਜਾਬੀ",
        value:"Punjabi"
    },
    {
        id:9,
        name:"Gujarati",
        langName:"ગુજરાતી",
        value:"Gujarati"
    },
    {
        id:10,
        name:"Rajasthani",
        langName:"राजस्थानी",
        value:"Rajasthani"
    },
    {
        id:11,
        name:"Bhojpuri",
        langName:"भोजपुरी",
        value:"Bhojpuri"
    },
    {
        id:12,
        name:"Bengali",
        langName:"বাংলা",
        value:"Bengali"
    },
    {
        id:13,
        name:"Hindi",
        langName:"हिन्दी",
        value:"Hindi"
    },
    {
        id:14,
        name:"Assamese",
        langName:"অসমীয়া",
        value:"Assamese"
    },
]

export  {LangData}