import { AxiosResponse } from "axios";
import {
  SEND_OTP,
  SIGNUP,
  VERIFY_OTP,
  UPDATE_PROFILE_PIC,
} from "../utils/apiEndPoints";
import { OtpResponse, REQUEST_METHOD } from "../utils/common.types";
import { SignupData } from "../modules/Astro/LoginFlow/LoginFlow.type";
import axiosInstance from "@/utils/customAxios";

export const sendOtp = async (countryCode: string, contact: string) => {
  try {
    console.log("--------->>", countryCode, contact);
    const data = await axiosInstance<AxiosResponse>({
      method: REQUEST_METHOD.POST,
      url: SEND_OTP,
      data: { countryCode, contact },
    });
    console.log(data);
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const verifyOtp = async (
  countryCode: string,
  contact: string,
  otp: string,
  firebaseId?: string
) => {
  try {
    const data = await axiosInstance<OtpResponse>({
      method: REQUEST_METHOD.POST,
      url: VERIFY_OTP,
      data: { countryCode, contact, otp, firebaseId },
    });
    if (data.data.code === 200) {
      localStorage.setItem("token", data?.data?.accessToken);
      localStorage.setItem("userId", data?.data?.userData?._id);
    }
    return data?.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const signup = async (reqBody: SignupData) => {
  try {
    const data = await axiosInstance<OtpResponse>({
      method: REQUEST_METHOD.POST,
      url: SIGNUP,
      data: { ...reqBody, profilePic: undefined },
    });
    if (data?.data?.code === 200) {
      if (reqBody?.profilePic) {
        await updateProfilePic(reqBody?.profilePic, data?.data?.accessToken);
      }
      localStorage.setItem("token", data?.data?.accessToken);
      localStorage.setItem("userId", data?.data?.userData?._id);
    }
    return data?.data;
  } catch (e) {
    console.log(e);
    return null;
  }
};

export const updateProfilePic = async (file: File, token: string) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    await axiosInstance({
      method: REQUEST_METHOD.POST,
      url: UPDATE_PROFILE_PIC,
      data: formData,
      params: {
        token: token,
      },
      headers: {
        "Content-type": "multipart/form-data",
      },
    });
  } catch (e) {
    console.log(e);
    return null;
  }
};
