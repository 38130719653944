import { throttle } from "@/utils/helper";
import styles from "./IosDatePicker.module.scss";
import { useCallback, useEffect, useRef } from "react";

interface IosDatePickerProps {
  date: Date;
  onChange: (val: Date) => void;
}

const IosDatePicker = ({ date, onChange }: IosDatePickerProps) => {
  const dateRef = useRef<HTMLUListElement>(null);
  const monthRef = useRef<HTMLUListElement>(null);
  const yearRef = useRef<HTMLUListElement>(null);
  const currYear = new Date().getFullYear();

  const INITIAL_SCROLL_POS = 33;
  const SCROLL_STEP = 65;

  const throttleFunc = useCallback(throttle(onChange, 200), []);

  useEffect(() => {
    dateRef?.current?.scrollTo({
      top: date?.getDate() * SCROLL_STEP,
      behavior: "smooth",
    });
    monthRef?.current?.scrollTo({
      top: (date?.getMonth() + 1) * SCROLL_STEP,
      behavior: "smooth",
    });
    yearRef?.current?.scrollTo({
      top: (currYear - date?.getFullYear() + 1) * SCROLL_STEP,
      behavior: "smooth",
    });
  }, [dateRef, monthRef, yearRef]);

  return (
    <div className={styles.iosPicker}>
      <ul
        ref={dateRef}
        onScroll={(e) => {
          const val = e.currentTarget.scrollTop;
          const pos = Math.ceil((val - INITIAL_SCROLL_POS) / SCROLL_STEP + 0.5);
          const newDate = new Date(date);
          newDate.setDate(pos);
          throttleFunc(newDate);
        }}
      >
        {Array(31)
          .fill(null)
          .map((_, index) => (
            <li
              className={date.getDate() === index + 1 ? styles.selected : ""}
              key={index}
            >
              {index < 9 ? `0${index + 1}` : index + 1}
            </li>
          ))}
      </ul>
      <ul
        ref={monthRef}
        onScroll={(e) => {
          const val = e.currentTarget.scrollTop;
          const pos =
            Math.ceil((val - INITIAL_SCROLL_POS) / SCROLL_STEP + 0.5) - 1;
          const newDate = new Date(date);
          newDate.setMonth(pos);
          throttleFunc(newDate);
        }}
      >
        {Array(12)
          .fill(null)
          .map((_, index) => (
            <li
              className={date.getMonth() === index ? styles.selected : ""}
              key={index}
            >
              {index < 9 ? `0${index + 1}` : index + 1}
            </li>
          ))}
      </ul>
      <ul
        ref={yearRef}
        onScroll={(e) => {
          const val = e.currentTarget.scrollTop;
          const pos =
            Math.ceil((val - INITIAL_SCROLL_POS) / SCROLL_STEP + 0.5) - 1;
          const year = currYear - pos;
          const newDate = new Date(date);
          newDate.setFullYear(year);
          throttleFunc(newDate);
        }}
      >
        {Array(200)
          .fill(null)
          .map((_, index) => (
            <li
              className={
                date.getFullYear() === currYear - index ? styles.selected : ""
              }
              key={index}
            >
              {currYear - index}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default IosDatePicker;
