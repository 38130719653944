import { useContext, useEffect, useState } from "react";
import CallFeed from "./CallFeed/CallFeed";
import CallEndAndMuteButton from "./CallEndAndMuteButton/CallEndAndMuteButton";
import styles from "./CallScreen.module.scss";
import { AppContext } from "@/context/AppContext";
import { endCall } from "@/services/call.service";
import useWarnOnExit from "@/customhooks/useWarnOnExit";
import Popup from "../Popup/Popup";
import ProfileCard from "@/modules/Astro/RoomsFeed/ProfileCard/ProfileCard";

interface CallScreenPopuppropType {
  show: boolean;
  onClose: () => void;
}

const CallScreenPopup = ({ show, onClose }: CallScreenPopuppropType) => {
  const context = useContext(AppContext);
  const {
    auth,
    trtcClient,
    privateCallData,
    privateCallSocket,
    setPrivateCallData = () => null,
    isCallScreen,
    setIsCallScreen,
  } = context ?? {};
  const { token } = context?.auth ?? {};
  const { caller, contentOwner } = context?.privateCallData ?? {};
  const [isCalling, setIsCalling] = useState<boolean>(true);
  const [isReviewPopup, setIsReviewPopup] = useState<boolean>(false);

  useWarnOnExit(() => {
    if (trtcClient && privateCallData) {
      setPrivateCallData({});
      void endCall(trtcClient, privateCallSocket);
    }
  });

  const getUserDetails = async () => {
    // const data = await getUserData(
    //   privateCallData?.contentOwner?.userId,
    //   auth?.token
    // );
    // const newPrivateCallData = { ...privateCallData };
    // if (data?.isFollowing && newPrivateCallData?.caller) {
    //   newPrivateCallData.caller.isFollowing = data?.isFollowed;
    // }
    // setPrivateCallData(newPrivateCallData);
  };

  useEffect(() => {
    if (auth?.token && auth?.userId) {
      void getUserDetails();
    }
  }, [auth?.token, auth?.userId]);

  return (
    <Popup show={show} onClickOutside={false} onClose={onClose}>
      <div className={styles.feedWrapper}>
        <div className={styles.liveRoomWrapper}>
          <div className={styles.midSection}>
            <CallFeed />
          </div>
          <div className={styles.rightSection}>
            <div>
              <ProfileCard
                icon={contentOwner?.photo ?? ""}
                name={contentOwner?.name || ""}
                members={0}
                // downloadPopup={() => null}
              />
              {/* <LiveInteractionSection /> */}
            </div>
          </div>
          {isCalling && <CallEndAndMuteButton />}
        </div>
      </div>
    </Popup>
  );
};

export default CallScreenPopup;
