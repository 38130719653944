/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable import/no-unresolved */

import { ProfileInfoDataInterface } from "@/modules/Astro/UserProfile/ProfilesInfo/profileTypes";
import {
  UserInfoApiResponse,
  UserInfoDataApiResponse,
} from "@/modules/Astro/UserProfile/types";
import { REQUEST_METHOD } from "@/types/common.types";
import axiosInstance from "@/utils/customAxios";
import { AxiosResponse } from "axios";

export const getUserInfo = async (
  userName: string,
  token = ""
): Promise<ProfileInfoDataInterface> => {
  try {
    const res: AxiosResponse<UserInfoDataApiResponse> = await axiosInstance({
      method: REQUEST_METHOD.GET,
      url: "/users/user",
      params: { userName },
    });

    const data: AxiosResponse<UserInfoApiResponse> = await axiosInstance({
      method: REQUEST_METHOD.GET,
      url: "/users/profile-info",
      params: {
        token,
        profileId: res?.data?.data?._id,
      },
    });
    return data.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export interface FollowUsersApiResponse {
  code: number;
  data: {
    followId: number;
  };
  message: string;
  error: string;
}

export const followUsers = async (
  Ids = {},
  token = ""
): Promise<FollowUsersApiResponse> => {
  try {
    const res: AxiosResponse<FollowUsersApiResponse> = await axiosInstance({
      method: REQUEST_METHOD.POST,
      url: "/users/followUsers",
      data: Ids,
      params: { token },
    });
    return res.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
