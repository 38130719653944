import React, { useEffect, useContext } from "react";
import styles from "./Toast.module.scss";
import Image from "next/image";
import { CLOSE_ICON, TOAST_ICON } from "@/utils/imageRelativePaths";
import { AppContext } from "@/context/AppContext";

const Toast = () => {
  const context = useContext(AppContext);
  const { ToastData = {}, setToastData = () => null } = context ?? {};

  useEffect(() => {
    let timer: NodeJS.Timeout | undefined;
    if (ToastData?.type && !ToastData?.visible) {
      setTimeout(() => {
        setToastData((pre) => ({ ...pre, visible: true }));
      }, 200);

      timer = setTimeout(() => {
        setToastData({});
      }, 5000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [ToastData]);

  const handleClose = () => {
    setToastData({});
  };

  return (
    <div
      className={`${styles.toastContainer} ${
        ToastData?.visible ? styles.showToast : ""
      } ${styles[ToastData?.type as string]}`}
    >
      <div className={styles.toast_content}>
        <Image
          className={styles.statusIcon}
          src={
            TOAST_ICON[ToastData?.type?.toLocaleUpperCase() as string] ??
            "/assets/icons/toast-warning.svg"
          }
          alt="icon"
          width={20}
          height={20}
        />
        <span className={styles.message}>{ToastData?.message}</span>
        <Image
          className={styles.close_icon}
          src={CLOSE_ICON}
          alt="close"
          height={10}
          width={10}
          onClick={handleClose}
        />
      </div>
    </div>
  );
};

export default Toast;
