import Image from "next/image";
import PopupHeader from "../PopupHeader";
import { LangData } from "./lang";
import styles from "./LanguageSelection.module.scss";
import { ChingariButton } from "chingari-components";
import { LOGIN_FLOW_STRINGS } from "@/utils/strings";
import { IC_RADIO_TICK } from "@/utils/imageRelativePaths";

interface LanguageSelectionProps {
  value: string;
  onChange: (name: string, value: string) => void;
  onNext: () => void;
}

const LanguageSelection = ({
  value,
  onChange,
  onNext,
}: LanguageSelectionProps) => {
  return (
    <div className={styles.languageWrapper}>
      <PopupHeader
        title={LOGIN_FLOW_STRINGS.LANG_SELECT_TITLE}
        description={LOGIN_FLOW_STRINGS.LANG_SELECT_DESC}
      />
      <div className={styles.langSelectWrapper}>
        {LangData?.map(({ id, name, langName, value: val }) => (
          <div
            onClick={() => onChange("language", val)}
            key={id}
            className={`${styles.langItem} ${
              value === val ? styles.selected : ""
            }`}
          >
            <div>
              <p className={styles.langName}>{langName}</p>
              <p className={styles.name}>{name}</p>
            </div>
            {val === value ? (
              <Image width={16} height={16} alt="tick" src={IC_RADIO_TICK} />
            ) : (
              <div className={styles.radio} />
            )}
          </div>
        ))}
      </div>

      <ChingariButton
        title={LOGIN_FLOW_STRINGS.NEXT_BUTTON}
        className={styles.button}
        onClick={onNext}
        disabled={!value}
      />
    </div>
  );
};

export default LanguageSelection;
