import { ChingariButton } from "chingari-components";
import styles from "./NameInput.module.scss";
import { useEffect, useRef } from "react";
import { LOGIN_FLOW_STRINGS } from "@/utils/strings";

interface NameInputProps {
  value: string;
  onChange: (name: string, value: string) => void;
  onNext: () => void;
}

const NameInput = ({ value, onChange, onNext }: NameInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // auto Focous
  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Focus on the textarea after rendering
    }
  }, []);
  return (
    <div className={styles.nameInputWrapper}>
      <h3 className={styles.title}>{LOGIN_FLOW_STRINGS.NAME_INPUT_TITLE}</h3>
      <input
        placeholder={LOGIN_FLOW_STRINGS.NAME_INPUT_PLACEHOLDER}
        className={styles.input}
        value={value}
        onChange={(e) => onChange("name", e.target.value)}
        ref={inputRef}
      />
      <ChingariButton
        title={LOGIN_FLOW_STRINGS.NEXT_BUTTON}
        className={styles.button}
        onClick={onNext}
        disabled={!value}
      />
    </div>
  );
};

export default NameInput;
