import {
  FEMALE_IMG,
  MALE_IMG,
  OTHER_IMG,
} from "../../../../utils/imageRelativePaths";

const GenderData = [
  {
    id: 1,
    name: "Male",
    value: "0",
    image: MALE_IMG,
  },
  {
    id: 2,
    name: "Female",
    value: "1",
    image: FEMALE_IMG,
  },
  {
    id: 3,
    name: "Other",
    value: "2",
    image: OTHER_IMG,
  },
];

export { GenderData };
