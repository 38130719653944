"use client";
import styles from "./header.module.scss";
import Image from "next/image";
import { AstroImages } from "../constants";
import { useContext, useState } from "react";
import { AppContext } from "@/context/AppContext";
import { useRouter } from "next/navigation";
import {
  DIAMOND_ICON_PINK,
  RUPEE_PURPLLE_ICON,
  RUPEE_WHITE_ICON,
} from "@/utils/imageRelativePaths";
import insufficientBalancePopup from "@/modules/Astro/components/InsufficientBalancePopup";
export default function Header() {
  const router = useRouter();
  const NavLinks = [
    "Home",
    "Horoscope",
    "Connect",
    "Reports",
    "Book a pooja",
    "Shop",
    "Blogs",
  ];
  const [mobileHeader, setMobileHeader] = useState<boolean>(false);

  const context = useContext(AppContext);
  const { setPopups, auth, userData } = context ?? {};
  const { token } = auth ?? {};
  const handleClickLogin = () => {
    setPopups &&
      setPopups({
        showLoginPopup: true,
      });
  };
  const handleClickLogout = () => {
    setPopups &&
      setPopups({
        logoutPopup: true,
      });
  };
  return (
    <div className={styles["header_cont"]}>
      <div className={styles["logo_cont"]} onClick={() => router.push("/")}>
        <img
          src={
            "https://media.chingari.io/apipublic/chingari-web-assets/images/astro/astro-icon.png"
          }
          alt="Astro"
        />
        <p>ASTROLIVE</p>
      </div>
      <div className={styles["links_cont"]}>
        {[].map((link, index) => (
          <div key={index}>{link}</div>
        ))}
      </div>
      {token ? (
        <div
          className={styles.wallet_diamonds_count}
          onClick={() => router.push("/store")}
        >
          <Image
            src={RUPEE_PURPLLE_ICON}
            alt="dimond-ic"
            height={29}
            width={31}
          />
          <span className={styles.count}>{userData?.diamonds}</span>
        </div>
      ) : (
        ""
      )}
      {token ? (
        <button onClick={handleClickLogout} className={styles["login_btn"]}>
          LOGOUT
        </button>
      ) : (
        <button onClick={handleClickLogin} className={styles["login_btn"]}>
          LOGIN
        </button>
      )}
      <div className={styles["menu_icon_cont"]}>
        <Image
          src={mobileHeader ? AstroImages.CLOSE_ICON : AstroImages.MENU_ICON}
          onClick={() => setMobileHeader((val) => !val)}
          alt={"menu"}
          className={styles["menu_icon"]}
        />
      </div>
      <div
        className={`${styles["mobile_header"]} ${
          mobileHeader ? styles["show_mobile_header"] : ""
        }`}
      >
        <div className={styles["links_cont"]}>
          {NavLinks.map((link, index) => (
            <div className={styles["link"]} key={index}>
              {link}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
