import { useState } from "react";
import Image from "next/image";
import { ChingariButton } from "chingari-components";
import PopupHeader from "../PopupHeader";
// import { ASTRO, IC_PLUS } from "../../../utils/imageRelativePaths";
import styles from "./UploadPic.module.scss";
import { LOGIN_FLOW_STRINGS } from "@/utils/strings";
import { ASTRO_LOGO_IMG, IC_PLUS } from "@/utils/imageRelativePaths";

interface UploadPicProps {
  file: File;
  onFileChange: (file: File) => void;
  onNext: () => void;
}

const UploadPic = ({ file, onFileChange, onNext }: UploadPicProps) => {
  return (
    <div className={styles.picSelectionWrapper}>
      <PopupHeader
        title={LOGIN_FLOW_STRINGS.UPLOAD_PIC_TITLE}
        description={LOGIN_FLOW_STRINGS.UPLOAD_PIC_DESC}
      />

      <div className={styles.inputWrapper}>
        {file ? (
          <div className={styles.profilePic}>
            <Image
              src={URL.createObjectURL(file)}
              alt="profile"
              width={200}
              height={200}
            />
          </div>
        ) : (
          <div className={styles.imgWrapper}>
            <Image src={ASTRO_LOGO_IMG} width={54} height={66} alt="fire" />
            <div className={styles.plus}>
              <Image src={IC_PLUS} alt="plus" width={20} height={20} />
            </div>
          </div>
        )}
        <input
          type="file"
          className={styles.input}
          onChange={(e) => onFileChange(e.target.files?.[0] as File)}
        />
      </div>

      <div className={styles.skip} onClick={onNext}>
        {LOGIN_FLOW_STRINGS.SKIP_BUTTON}
      </div>

      <ChingariButton
        title={LOGIN_FLOW_STRINGS.NEXT_BUTTON}
        className={styles.button}
        onClick={onNext}
        disabled={!file}
      />
    </div>
  );
};

export default UploadPic;
