import React, { useContext } from "react";
import styles from "./CallFeed.module.scss";
import AddDiamondCard from "../../AddDiamondCard/AddDiamondCard";
// import MobileOverlay from "../../RoomsFeed/DailyFeeds/MobileOverlay/MobileOverlay";
import { Config } from "@/utils/config";
import { AppContext } from "@/context/AppContext";
// import { AppContext } from "@/context/AppContext";

const CallFeed = () => {
  const context = useContext(AppContext);
  const { privateCallData, setPrivateCallData } = context ?? {};
  const { contentOwner, lowDiamondPopup } = context?.privateCallData ?? {};

  const onClose = () => {
    setPrivateCallData &&
      setPrivateCallData({ ...privateCallData, lowDiamondPopup: false });
  };

  return (
    <>
      <div
        id={`${contentOwner?.userId ?? ""}_main`}
        className={styles.remoteContainer}
      >
        <div id="" className={styles.local_video_wrapper}>
          <div id="LocalVideo" className={styles.localContainer} />
        </div>
        {/* <MobileOverlay
          icon={`${Config.S3_URL}/${contentOwner?.photo || ""}`}
          name={contentOwner?.name || ""}
          members={0}
          downloadPopup={() => null}
          contentUserId={contentOwner?.userId || ""}
        /> */}
        {lowDiamondPopup && <AddDiamondCard onClose={onClose} />}
      </div>
    </>
  );
};

export default CallFeed;
